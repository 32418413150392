@use "breakpoints";
@use "colors";

.classes {
  background-color: colors.$black;
  color: colors.$white;
  margin-block-end: 50px;
  min-block-size: 100vh;
  overflow: hidden;
  position: relative;

  @media (width >= breakpoints.$sm) {
    margin-block-end: 140px;
  }
}
