@import "variables";

.cta-underline {
  background: none;
  border: none;
  color: $gray;
  font-size: 14px;
  margin-inline-start: 5px;
  text-decoration: underline;
  text-decoration-color: $gray;

  &:focus,
  &:active {
    outline: 1px solid #000;
    outline-offset: 3px;
  }
}
