.screen {
  .reason {
    + .reason {
      margin-block-start: 2.4rem;
    }
  }

  .textarea {
    margin-block-start: 2.4rem;
  }

  .error-message {
    margin-block-start: 4.8rem;
  }
}
