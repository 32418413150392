@use "breakpoints";
@use "colors";
@use "typography";

.error-alert {
  @include typography.body-small;

  align-items: center;
  background-color: colors.$light-gray;
  display: flex;
  padding: 0.8rem 1.6rem;

  svg {
    block-size: 1.6rem;
    fill: colors.$error-red;
    flex: 0 0 auto;
    inline-size: 1.6rem;
    margin-inline-end: 0.8rem;
  }

  @media (width >= breakpoints.$md) {
    max-inline-size: 70%;
  }
}
