@use "variables";

.add-to-calendar-button {
  align-items: center;
  background: transparent;
  block-size: 52px;
  border: 1px solid variables.$black;
  color: variables.$black;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  inline-size: 327px;
  justify-content: center;
  margin: 0;
}
