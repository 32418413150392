@import "mixins";
@import "custom";
@import "variables";

.input-container {
  margin-block-end: 31px;
  padding: 0 48px;
  position: relative;

  .search-icon-img {
    background-size: 64%;
    block-size: 18px;
    inline-size: 18px;
    inset-block-start: 10px;
    inset-inline-start: 48px;
    margin: 0;
    opacity: 0.3;
    position: absolute;
  }

  .search-box {
    border-color: #999;
    border-radius: 0;
    border-width: 0 0 1px;
    font-size: 14px;
    padding: 11px 20px 8px 35px;

    &:focus {
      box-shadow: none;
      outline: 1px solid #fff;
    }
  }

  .mob-search-close {
    font-size: 16px;
    inset-block-start: 26px;
    inset-inline-end: 22px;
    position: absolute;
  }

  .input-close {
    color: #737373;
    cursor: pointer;
    inset-block-start: 11px;
    inset-inline-end: 50px;
    position: absolute;
  }
}

@media only screen and (width >= 768px) and (width <= 1024px) {
  .input-container {
    margin-block-end: 0;
    padding: 19px 0;

    .search-icon-img {
      inset-block-start: 28px !important;
      inset-inline-start: 35px !important;
    }

    .search-box {
      border: 0;
      font-size: 16px;
      padding: 11px 85px 11px 70px !important;
    }
  }
}

@media only screen and (width <= 667px) {
  .input-container {
    margin-block-end: 0;
    padding: 19px 0;

    .search-icon-img {
      inset-block-start: 28px !important;
      inset-inline-start: 22px !important;
    }

    .search-box {
      border: 0;
      font-size: 16px;
      padding: 11px 85px 11px 57px !important;
    }
  }
}
