@use "colors";
@import "mixins";
@import "custom";
@import "variables";

.group-fitness {
  section.background-wrapper {
    background-color: colors.$black;
    background-size: cover;

    section.class-search {
      &.hamptons {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-block-size: 650px;
      }

      block-size: 100%;

      .hero-heading {
        color: #fff;
        font-size: 104px;
        font-weight: 500;
        text-transform: uppercase;

        &.hamptons {
          font-size: 96px;
          line-height: 96px;
        }
      }
    }
  }

  .search-header {
    display: flex;
    justify-content: space-between;
    padding-block: 18px;

    .dropdown-container {
      display: flex;

      .classes-count {
        color: #fff;
        font-size: 14px;
        padding: 8px 0 6px 8px;
      }
    }
  }

  .mobile-header {
    background-color: #151515;
    block-size: 60px;
    color: #fff;
    padding: 7px 7px 12px;
  }

  .black-bg {
    background-color: #000;
  }
}

@media only screen and (width >= 768px) {
  .group-fitness {
    .hamptons-background-wrapper {
      background-position: 68% 86% !important;
      background-size: cover !important;
      min-block-size: 650px !important;
    }

    section.class-search {
      min-block-size: unset !important;
      padding: 0 48px;

      &.hamptons {
        min-block-size: 650px !important;
        position: relative;
      }

      .mobile-dropdown-section {
        inline-size: calc(100% - 48px);
        inset-block-end: 0;
        inset-inline-start: 48px;
        padding-block-end: 40px;
        position: absolute;
      }
    }

    .hero-heading {
      font-size: 72px !important;
      inline-size: 585px !important;
    }
  }
}

@media only screen and (width >= 1025px) {
  .group-fitness {
    .hero-heading {
      font-size: 104px !important;
      inline-size: 834px !important;
      line-height: 104px !important;

      &.hamptons {
        font-size: 96px !important;
        inline-size: 800px !important;
        line-height: 96px !important;
      }
    }
  }
}

@media only screen and (width <= 767px) {
  .group-fitness {
    section.hamptons-background-wrapper {
      background-position: 55% !important;
      background-size: cover !important;
      min-block-size: 350px !important;
    }

    .result-section {
      .result-container {
        padding: 0 23px;
      }
    }

    section.class-search {
      &.hamptons {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-block-size: 350px !important;
      }

      .hero-heading {
        font-size: 64px !important;
        inline-size: 286px !important;
        margin-block-end: 31px !important;
        padding-block-start: 48px !important;

        &.hamptons {
          font-size: 60px !important;
          line-height: 60px !important;
        }
      }

      .result-container {
        padding: 0 24px;
      }

      .mobile-dropdown-section {
        padding: 0 10px 40px 24px;
      }
    }
  }
}
