@use "typography";
@use "breakpoints";

.primary-headline {
  @include typography.xl-headline;

  color: inherit;

  @media (max-width: breakpoints.$sm) {
    &[data-is-long="true"] {
      font-size: 3.8rem;
    }
  }
}
