@import "mixins";
@import "custom";
@import "variables";

.help-text {
  color: #737373;
  font-size: 14px;
  padding: 0 30px 0 48px;
}

.custom-dropdown {
  background-color: transparent;
  block-size: 36px;
  border: solid 1px #999;
  border-radius: 4px;
  color: #999;
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-inline-end: 12px;
  padding: 6px 12px;

  &.drp-location {
    inline-size: 154px;
  }

  &.drp-class {
    inline-size: 105px;
  }

  &.drp-more {
    inline-size: 105px;
  }

  .drp-text {
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .drp-loc-text {
    inline-size: 140px;
  }

  .drp-class-text {
    inline-size: 70px;
  }

  .drp-more-text {
    inline-size: 70px;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &.active {
    background-color: #fff;
    color: #000;
  }
}

.checkmark {
  background-color: #fff;
  block-size: 13px;
  border: 1px solid #000;
  border-radius: 50%;
  inline-size: 13px;
  inset-block-start: 4px;
  inset-inline-start: 0;
  position: absolute;

  &::after {
    content: "";
    display: none;
    position: absolute;
  }
}

.container-check {
  cursor: pointer;
  display: block;
  font-size: 14px;
  min-inline-size: 250px;
  padding-inline-start: 35px;
  position: relative;
  user-select: none;

  .check-text {
    // padding-top: 2px;
    display: block;
  }

  .checkmark::after {
    block-size: 7px;
    border: solid #fff;
    border-width: 0 1px 1px 0;
    inline-size: 4px;
    inset-block-start: 1px;
    inset-inline-start: 4px;
    transform: rotate(45deg);
  }

  .club-input {
    block-size: 0;
    cursor: pointer;
    inline-size: 0;
    opacity: 0;
    position: absolute;

    &:checked {
      & ~ .checkmark {
        background-color: #000;

        &::after {
          display: block;
        }
      }
    }
  }
}

.mobile-drp {
  background-color: #fff;
  block-size: 100vh;
  inline-size: 100%;
  inset-block-start: 0;
  inset-inline-start: 0;
  position: fixed;
  z-index: 1030;

  .mob-filterscroll {
    max-block-size: 66vh;
    overflow-y: auto;
  }

  .drp-title {
    align-items: center;
    background-color: #f3f3f3;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }

  .morning-session {
    border-block-start: 0 !important;
  }

  .input-container {
    margin-block-end: 0;
    padding: 19px 0;

    .search-box {
      border: 0;
    }
  }

  .dropdown-item {
    border-block-end: 1px solid #ccc;
    list-style: none;
    padding: 21px 0 21px 48px;
  }

  li:first-of-type {
    border-block-start: 1px solid #ccc;
  }

  .view-categories {
    cursor: pointer;
    font-size: 14px;
  }

  .btn-grp {
    inline-size: 100%;
    inset-block-end: 0;
    position: fixed;

    .btn {
      block-size: 52px;
      border: none;
      border-radius: 0;
      inline-size: 50%;
      padding: 13px 0 15px;

      &.btn-clear {
        background-color: #f3f3f3;
      }

      &.btn-apply {
        background-color: #000;
        color: #fff;
      }

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.mobile-drp-more {
  .dropdown-item {
    display: flex;
    justify-content: space-between;
  }

  .city-name {
    border-block-start: 1px solid #ccc;
    padding: 25px 20px 25px 50px;
  }

  .back-arrow {
    block-size: 18px;
    inline-size: 18px;
    margin-inline-end: 9px;
  }

  .arrow-next {
    padding-inline-end: 16px;

    .filter-count {
      margin-inline-end: 20px;
    }
  }
}

.dropdown-menu {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 20%);
  inset-block-start: 41px;
  inset-inline-end: 12px;
  min-inline-size: 376px;
  padding: 34px 0 47px;
  position: absolute;
  z-index: 999;

  .drp-close {
    block-size: 20px;
    cursor: pointer;
    float: right;
    font-size: 12px;
    inline-size: 20px;
    margin-block-start: -16px;
    margin-inline-end: 20px;
  }

  .city-name {
    cursor: pointer;
    font-size: 14px;
    padding: 9px 48px 11px 42px;

    .back-arrow {
      block-size: 25px;
      inline-size: 25px;
      margin-inline-end: 17px;
    }
  }

  .scroll {
    max-block-size: 308px;

    // height: 350px;
    overflow-y: auto;
  }

  #custom-scrollbar {
    ::-webkit-scrollbar-track {
      display: none;
    }

    ::-webkit-scrollbar {
      border-radius: 20px;
      inline-size: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 1px;
    }
  }

  .dropdown-item {
    background: #fff;
    color: $black !important;
    cursor: pointer;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 9px 30px 11px 48px;
    transition: all 0.2s;

    .drp-item-arrow {
      margin-block-start: 2px;
    }
  }
}

.dropdown-more {
  .col-right {
    border-inline-start: 1px solid #ccc;
  }

  .dropdown-menu {
    min-inline-size: 677px;

    .input-container {
      margin-block-end: 40px;
    }

    .class-time {
      font-size: 14px;
      padding: 12px 0 40px 48px;
    }
  }
}

.dropdown-classes {
  .dropdown-menu {
    min-inline-size: 376px;
  }
}

.classes-selected {
  display: flex;
  flex-flow: wrap;
  margin-block-start: 20px;
  padding: 0 48px;
}

.mobile-class-selected {
  display: flex;
  flex-flow: wrap;
  padding: 24px 37px 24px 48px;
}

.mob-selected-scroll {
  block-size: auto;
  max-block-size: 35vh;
  overflow-y: auto;
}

.class-name {
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 4px;
  display: flex;
  font-size: 14px;
  margin-block-end: 10px;
  margin-inline-end: 10px;
  padding: 5px 15px 7px;

  .close {
    block-size: 15px;
    cursor: pointer;
    inline-size: 15px;
    margin-inline-start: 8px;
  }
}

.clear-all {
  cursor: pointer;
  padding: 15px 48px 0 0;
  text-align: end;
}

.down-arrow {
  block-size: 9px;
  inline-size: 9px;
  margin-block-start: 7px;
}

@media only screen and (width >= 768px) and (width <= 1024px) {
  .checkmark {
    block-size: 18px;
    inline-size: 18px;
    inset-block-start: 2px;
  }

  .container-check {
    .checkmark {
      &::after {
        block-size: 10px;
        border-width: 0 2px 2px 0;
        inline-size: 5px;
        inset-block-start: 2px;
        inset-inline-start: 5.5px;
      }
    }
  }

  .drp-title {
    padding: 18px 18px 22px 35px;
  }

  .view-categories {
    margin: 23px 0 25px 35px;
  }

  .mobile-drp {
    .drp-list {
      block-size: calc(100vh - 52px);
      overflow-y: auto;
      padding-block-end: 114px;
    }

    .dropdown-item {
      padding: 23px 0 18px 35px !important;
    }
  }

  .mobile-drp-more {
    .arrow-bck {
      margin-inline-end: 14px;
    }

    .city-name {
      padding: 25px 20px 25px 38px;
    }
  }

  .custom-dropdown {
    inline-size: 199px;

    .drp-class-text {
      inline-size: 140px;
    }

    .drp-more-text {
      inline-size: 140px;
    }
  }
}

@media only screen and (width <= 667px) {
  .checkmark {
    block-size: 18px;
    inline-size: 18px;
    inset-block-start: 2px;
  }

  .drp-title {
    padding: 18px 18px 22px 24px;
  }

  .view-categories {
    margin: 23px 0 25px 24px;
  }

  .container-check {
    .checkmark {
      &::after {
        block-size: 10px;
        border-width: 0 2px 2px 0;
        inline-size: 5px;
        inset-block-start: 2px;
        inset-inline-start: 5.5px;
      }
    }
  }

  .mobile-drp {
    .drp-list {
      block-size: calc(100vh - 52px);
      overflow-y: auto;
      padding-block-end: 114px;
    }

    .dropdown-item {
      padding: 23px 0 18px 24px !important;
    }

    .mobile-class-selected {
      padding: 24px 37px 24px 24px !important;
    }
  }

  .custom-dropdown {
    .drp-class-text {
      inline-size: 140px;
    }

    .drp-more-text {
      inline-size: 140px;
    }
  }

  .mobile-drp-more {
    .arrow-bck {
      margin-inline-end: 14px;
    }

    .city-name {
      padding: 25px 20px 25px 23px;
    }
  }
}
