@use "breakpoints";
@use "buttons";
@use "colors";
@use "typography";
@import "mixins";
@import "custom";

// #PLEASE NOTE# - The styles in the module are all
// mobile-first unlike most of the styles in the project.

.personalize-visit {
  background-color: colors.$light-gray;
  block-size: 252px;
  display: flex;
  flex-direction: column;
  inline-size: 100vw;
  margin: 40px -24px -24px;
  padding: 40px 24px 0;
  position: relative;

  @include media-breakpoint-up(md) {
    inline-size: 100%;
    margin: 40px 0 0;
    padding: 40px 24px 0;
  }

  @include media-breakpoint-up(lg) {
    margin-block-start: 48px;
  }

  &[aria-expanded="true"] {
    block-size: auto;
  }
}

.heading-text {
  @include typography.body;

  margin: 0;
  padding: 0;
}

.sub-heading-text {
  @include typography.body-small;

  margin: 16px 0 10px;
  padding: 0;

  span {
    float: right;
  }
}

.cta-button {
  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

  margin-block: 48px 24px;

  &[disabled],
  &[aria-disabled="true"] {
    background-color: colors.$gray;
    border-color: colors.$gray;
    color: colors.$dark-gray;
  }
}

.quiz-item-container {
  margin-block-start: 32px;

  &[data-context="variant"] {
    display: none;
  }
}

.quiz-item {
  align-items: center;
  display: flex;
  max-inline-size: 500px;

  /* stylelint-disable-next-line selector-class-pattern */
  :global .MuiFormControlLabel-label {
    @include typography.body-small;

    font-family: inherit;
    margin-inline-start: 15px;
  }
}

.quiz-item-checkbox {
  block-size: 20px;
  border-radius: 0;
  color: colors.$medium-gray;
  inline-size: 20px;

  /* stylelint-disable-next-line selector-class-pattern */
  &:global.Mui-checked {
    color: colors.$black;
  }
}

@media (width <= breakpoints.$md) {
  .cta-button-container {
    background-color: colors.$light-gray;
    inset-block-end: 0;
    position: sticky;
  }
}

body[data-vwo-web3534] {
  .quiz-item-container {
    &[data-context="control"] {
      display: none;
    }

    &[data-context="variant"] {
      display: block;
    }
  }
}

.other-option-container {
  display: flex;
  flex-direction: column; /* Stack items vertically only for 'Other' */
}

.other-textarea {
  block-size: 80px;
  border: 1px solid rgba(0, 0, 0, 30%);
  border-radius: 0;
  color: #000;
  font-size: 1.6rem;
  inline-size: 100%;
  margin-block-start: 8px;
  padding: 1.6rem;
  resize: none;
}
