@use "breakpoints";
@use "buttons";
@use "colors";
@use "links";
@use "typography";

.choose-membership {
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  min-block-size: 100vh;
  position: relative;

  [class^="personal-info-module_"] {
    inline-size: calc(100% - 32px);
    margin: 40px 16px;
  }
}

.headline {
  flex: 0 0 100%;
}

.membership-options {
  display: flex;
  flex: 1 0 100%;
  flex-direction: column;
  padding: 0;
}

.lede {
  color: colors.$black;
  margin: 2.4rem;
}

.choose-membership [data-is="MembershipPlans"] {
  margin-block-end: 1.6rem;
}

.change-club-option {
  margin: 40px 24px;

  .plan-description {
    color: colors.$medium-gray;
    margin-block-start: 10px;
  }
}

.personal-info-module {
  inline-size: 100%;
  margin-inline: -10px;

  [class^="personalInfo"] {
    > div {
      > div {
        flex-basis: 100%;
        max-inline-size: 100%;
      }
    }
  }
}

.plans-form {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  inline-size: 100%;
}

.error-message-container {
  margin: 0 2.4rem;

  @media (min-width: breakpoints.$lg) {
    margin: 0;
  }
}

.error-message {
  color: colors.$error-red;
  padding: 15px 0;
}

.rejoin-message {
  color: colors.$black;
  padding-block-end: 32px;

  a {
    @include links.regular-text-link-dark;

    outline: none;

    &:focus {
      border-color: rgba(colors.$black, 0.6);
      color: rgba(colors.$black, 0.6);
    }
  }
}

.cta-wrapper {
  background-color: colors.$white;
  border-block-start: rgba(0, 0, 0, 15%) solid 1px;
  inset-block-end: 0;
  margin-block-start: auto;
  padding: 24px;
  position: sticky;

  button[type="submit"] {
    @include buttons.button(buttons.$theme-primary-dark, buttons.$width-wide);

    inline-size: 100%;
    outline: none;

    &:focus {
      outline: 2px solid rgba(0, 0, 0, 60%);
      outline-offset: 2px;
    }
  }
}

@media (min-width: breakpoints.$lg) {
  .choose-membership {
    background: colors.$white;
    flex-direction: row;

    [class^="personal-info-module_"] {
      inline-size: 100%;
      margin: inherit;
    }
  }

  .headline {
    flex-basis: 50%;
  }

  .lede {
    margin: 0 auto 24px;
  }

  .choose-membership [data-is="MembershipPlans"] {
    margin-block-end: 2.4rem;
  }

  .change-club-option {
    margin: 0;
  }

  .membership-options {
    block-size: 100vh;
    flex-basis: 50%;
    overflow-y: auto;
    padding: 120px 0 0;
  }

  .lede,
  .change-club-option,
  .choose-membership [data-is="MembershipPlans"],
  .error-message-container,
  .cta-wrapper {
    padding-inline: 124px;
  }

  .cta-wrapper {
    padding-block: 32px;

    button[type="submit"] {
      max-inline-size: 268px;
    }
  }

  .cta {
    inline-size: 280px;
  }
}
