@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.change-club {
  block-size: 100%;
  color: colors.$white;
  overflow: scroll;
  padding-block-start: 100px;

  @include media-breakpoint-down(sm) {
    padding-block-start: 65px;
  }

  h2 {
    @include typography.large-headline;
  }

  &-top {
    padding: 0 100px 0 124px;

    @include media-breakpoint-down(sm) {
      padding: 0 24px;
    }
  }

  &-inner {
    position: relative;
  }

  &-list {
    margin-block-start: 50px;
  }

  &-noNearBy {
    margin-inline-start: 10.9rem;

    @include media-breakpoint-down(sm) {
      margin-inline-start: 0;
    }
  }
}
