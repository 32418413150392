.mobile-container {
  align-items: center;

  .arrow {
    block-size: 18px;
    inline-size: 18px;
  }

  .day {
    block-size: 24px;
    font-size: 14px;
    inline-size: 33px;
    margin: 7px 2px 9px;
    text-align: center;

    &::after {
      content: "";
    }

    &.active {
      &::after {
        background-color: #fff;
        block-size: 4px;
        border-radius: 50%;
        content: "";
        inline-size: 4px;
        margin-block-start: 25px;
        margin-inline-start: -7px;
        position: absolute;
      }
    }
  }
}

.week-container {
  .arrow {
    block-size: 40px;
    color: #999;
    cursor: pointer;
    inline-size: 40px;
    padding: 13px 13.6px 13px 13px;
  }

  .day {
    color: #999;
    cursor: pointer;
    font-size: 14px;
    margin: 7px 12px 9px;

    &.active,
    &:active {
      background-color: white;
      border-radius: 4px;
      color: #000;
      margin: 2px 12px;
      padding: 5px 14px 7px 15px;
    }

    &:hover {
      border: solid 1px #999;
      border-radius: 4px;
      margin: 2px 12px;
      padding: 5px 15px 7px;
    }
  }
}
