@use "buttons";
@use "colors";
@use "typography";
@import "mixins";
@import "custom";

// #PLEASE NOTE# - The styles in the module are all
// mobile-first unlike most of the styles in the project.

.club-name-link {
  display: block;
  margin: 15px auto 0 0;

  svg {
    margin: -1px 8px 0 0;
  }
}

.address-block {
  margin: 0;

  img {
    float: left;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.address-text {
  margin: -2px 0 0 15px;
  padding: 0;
}

.edit-appointment {
  @include typography.cta-small;

  background-color: transparent;
  border: none;
  color: colors.$dark-gray;
  cursor: pointer;
  display: block;
  margin: 16px auto 0 0;
  padding: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin: -1px 0 0 8px;
  }
}

.add-to-calendar-button {
  margin-block-start: 24px;

  @include buttons.button(buttons.$theme-primary-dark, buttons.$width-primary);
}
