@use "breakpoints";
@use "colors";

.banner-container {
  background-color: colors.$light-gray;
  color: colors.$dark-gray;
  margin-block-end: 24px;
  margin-inline: 8px;
  padding: 15px 25px;

  @media (min-width: breakpoints.$lg) {
    margin-inline: 0;
    padding-inline: 124px;
  }
}
