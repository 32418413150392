@use "breakpoints";
@use "colors";

.back-button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: colors.$black;
  display: flex;
  flex-direction: row;
  font-size: 1.47rem;
  font-weight: 600;
  letter-spacing: 0.02em;
  line-height: 1;
  margin-block-end: 3.2rem;
  outline: 0;
  padding: 0;
  user-select: none;

  svg {
    block-size: 1.6rem;
    inline-size: 1.6rem;
    margin-inline-end: 0.8rem;
    padding: 0.2rem;
    transform: rotate(180deg);
  }

  &:hover {
    text-decoration: underline;

    svg {
      color: colors.$dark-gray;
    }
  }

  @media (width >= breakpoints.$md) {
    margin-block-end: 4rem;
  }
}
